import { Platform } from 'react-native'
import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  title: Platform.select({
    web: {
      fontFamily: 'Exo2',
      fontSize: 22,
      color: colors.logo,
      fontWeight: 'bold',
      paddingBottom: 2
    },
    ios: {
      fontFamily: 'Exo 2',
      fontSize: 22,
      color: colors.logo,
      fontWeight: 'bold'
    }
  }),
  title_null: Platform.select({
    web: {
      fontFamily: 'Exo2',
      fontSize: 22,
      color: colors.logo,
      fontWeight: 'bold',
      paddingBottom: 2
    },
    ios: {
      fontFamily: 'Exo 2',
      fontSize: 22,
      color: colors.logo,
      fontWeight: 'bold',
      paddingBottom: 5
    }
  }),
  subtitle: Platform.select({
    web: {
      fontFamily: 'Exo2',
      fontSize: 14,
      color: colors.logo
    },
    ios: {
      fontFamily: 'Exo 2',
      fontSize: 14,
      color: colors.logo,
      textAlign: 'center'
    }
  })
}))
