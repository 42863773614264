import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CounterState, Prediction, Step } from 'types/components'

const initialState: CounterState = {
  prediction: []
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state, action: PayloadAction<Prediction>) => {
      state.prediction.push(action.payload)
    },
    addStep: (state, action: PayloadAction<Step>) => {
      state.prediction[state.prediction.length - 1].steps.push(action.payload)
    }
  }
})

// Action creators are generated for each case reducer function
export const { increment, addStep } = counterSlice.actions

export default counterSlice.reducer
