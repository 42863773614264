import I18n from 'i18n-js'
import * as RNLocalize from 'react-native-localize'

import en from './locales/en.json'
import ru from './locales/ru.json'

const locales = RNLocalize.getLocales()

if (Array.isArray(locales)) {
  I18n.locale = locales[0].languageTag
}

I18n.defaultLocale = 'en'
I18n.fallbacks = true
I18n.translations = {
  ru,
  en
}

const lang = (key: I18n.Scope, optional = {}) => I18n.t(key, optional)

export { lang }
