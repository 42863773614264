import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  copyright: {
    fontSize: 14,
    fontFamily: 'Exo2',
    color: colors.text
  },
  row: { flex: 1, flexDirection: 'row', borderTopWidth: 1, borderTopColor: '#ccc', marginTop: 20 },
  rowMobile: { flex: 1, borderTopWidth: 1, borderTopColor: '#ccc', marginTop: 0 },
  left: { alignItems: 'flex-start', flex: 0.7, paddingTop: 5, paddingBottom: 10 },
  right: { alignItems: 'flex-end', flex: 0.5, paddingBottom: 15, justifyContent: 'center', paddingTop: 10 },
  center: { alignItems: 'flex-start', flex: 0.5, paddingBottom: 15 },
  link: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: 'Exo2'
  },
  bottom: {
    alignItems: 'flex-start',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    marginTop: 10
  }
}))
