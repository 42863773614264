import { useNavigation } from '@react-navigation/native'
import BlockRunes from 'components/block'
import { useStyles } from 'hooks/useStyles'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Text, useWindowDimensions, View } from 'react-native'
import { Button, Dialog, Paragraph } from 'react-native-paper'
import { useDispatch } from 'react-redux'
import { SCREEN_HOME } from 'res/screens'
import { increment } from 'store/counterRunes'
import { setTitle } from 'store/headerRunes'
// @ts-ignore
import { lang } from 'utils/lang'
import { setGlobalFn } from 'utils/utils'

import stylesConfig from './MainScreen.styles'

const MainScreen = () => {
  const styles = useStyles(stylesConfig)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [visible, setVisible] = useState(true)
  const { width, height } = useWindowDimensions()

  const backToHome = () => {
    // @ts-ignore
    navigation.replace(SCREEN_HOME, {})
  }

  useLayoutEffect(() => {
    if (!navigation.canGoBack()) {
      setGlobalFn(backToHome)
    }

    return () => setGlobalFn(undefined)
  }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(setTitle('ДЕМОВЕРСИЯ'))
      document.title = 'ДЕМОВЕРСИЯ'
    })

    return unsubscribe
  }, [navigation])

  const hideDialog = () => {
    setVisible(false)
  }

  const startPrediction = () => {
    dispatch(
      increment({
        date: new Date().getTime(),
        finished: false,
        steps: []
      })
    )
  }

  const isLandscape = () => {
    return width > height
  }

  const getBlockRunes = () => {
    if (isMobile && isLandscape()) {
      return <BlockRunes currentPage={currentPage} setCurrentPage={setCurrentPage} setVisible={setVisible} />
    } else if (!isMobile) {
      return <BlockRunes currentPage={currentPage} setCurrentPage={setCurrentPage} setVisible={setVisible} />
    }

    return (
      <View style={{ paddingTop: 20 }}>
        <Text style={{ fontSize: 18, fontFamily: 'Exo2', fontWeight: 'bold', color: 'red', textAlign: 'center' }}>Перейдите в ландшафтный вариант (поверните экран смартфона)</Text>
      </View>
    )
  }

  const getBlockDialog = () => {
    if (isMobile && isLandscape()) {
      return [styles.dialogStyleMobileLandscape, { width: width - 100 }]
    } else if (isMobile && !isLandscape()) {
      return [styles.dialogStyleMobile]
    } else if (!isMobile) {
      return [styles.dialogStyle]
    }
  }

  return (
    <>
      {getBlockRunes()}
      <Dialog visible={visible} style={getBlockDialog()}>
        <Dialog.Content style={styles.contentStyle}>
          <Paragraph style={styles.text}>
            {
              // eslint-disable-next-line max-len
              'Задаете вопрос, который касаться лично Вас, или какого-то события, связанного с Вами. Теперь, держа в голове заданный вопрос, начинайте выбирать Руны, показанные в картинах. Выберете картину, которой Вы отдаете предпочтение. После Вашего выбора эта картина исчезнет, и Вам надо будет выбрать вторую по предпочтению картину, а когда после Вашего выбора исчезнет и она, - третью, а потом четвертую. Не спешите и четко конкретизируйте свой выбор. Оракул, как нейросеть, вступает с Вами в диалог. Вы делаете выбор, а Оракул может позволить Вам сделать этот выбор, или, наоборот, запретить его. Если выбор той или другой Руны был запрещен на момент выбора, то после состоявшегося выбора другой Руны, этот запрет может быть снят. По окончанию всех шагов выбора Вам будет показан результат.'
            }
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions style={styles.actions}>
          <Button
            onPress={() => {
              hideDialog()
              startPrediction()
            }}
            labelStyle={styles.buttonTextOk}
          >
            {lang('main.start')}
          </Button>
          <Button
            icon={'close'}
            onPress={() => {
              hideDialog()
              // @ts-ignore
              navigation.navigate(SCREEN_HOME)
            }}
            labelStyle={styles.buttonText}
            style={styles.buttonStyle}
          >
            {lang('main.quit')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}

export default MainScreen
