import AppDarkModeToggle from 'components/header/toggle'
import { useStyles } from 'hooks/useStyles'
import React from 'react'
import { View } from 'react-native'
import { List } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { setDarkMode } from 'store/appRunes'
import { RootState } from 'store/store'

import stylesConfig from './ModeTheme.styles'

const ModeTheme = ({ colors }: any) => {
  const dispatch = useDispatch()
  const styles = useStyles(stylesConfig)
  const isDarkModeWeb = useSelector((state: RootState) => state.app.isDarkMode)

  const setIsDarkMode = () => {
    dispatch(setDarkMode(!isDarkModeWeb))
  }

  return (
    <List.Item
      title="Выбор темы"
      titleEllipsizeMode={'tail'}
      description="Светлая или темная тема"
      descriptionNumberOfLines={1}
      descriptionEllipsizeMode={'tail'}
      titleStyle={[styles.text, { color: colors.primary }]}
      descriptionStyle={[styles.description, { color: colors.primary }]}
      left={() => (
        <View style={{ justifyContent: 'center' }}>
          <AppDarkModeToggle isDarkModeWeb={isDarkModeWeb} setIsDarkMode={setIsDarkMode} />
        </View>
      )}
    />
  )
}

export default ModeTheme
