import React from 'react'
import DarkModeToggle from 'react-dark-mode-toggle'
import { View } from 'react-native'

const AppDarkModeToggle = ({ isDarkModeWeb, setIsDarkMode }: { isDarkModeWeb: boolean; setIsDarkMode: any }) => {
  return (
    <View style={{ paddingRight: 10 }}>
      <DarkModeToggle onChange={setIsDarkMode} checked={isDarkModeWeb} size={80} />
    </View>
  )
}

export default AppDarkModeToggle
