export const FEHU_PLUS = 'Новые начинания в реальности социума, получение и оперирование деньгами и собственностью в реальности социума; проявление в реальности социума нового жизненного этапа.'
export const FEHU_MINUS = 'Отсутствие или крах нового начинания, проблемы с работой или ее отсутствие, денежные или имущественные потери в реальности социума.'

export const URUZ_PLUS = 'Проявление упорства и настойчивости в реальности социума; наличие сил и энергии в реальности социума.'
export const URUZ_MINUS = 'Отсутствие энергии, сил, упорства и настойчивости, слабость, быстрая утомляемость в реальности социума.'

export const THURISAZ_PLUS = 'Расчистка хаоса, революционные перемены, проявление авторитарности в реальности социума.'
export const THURISAZ_MINUS = 'Хаос в реальности социума; неспособность расчистить хаос, покорное исполнение чьей-то воли в реальности социума.'

export const ANSUZ_PLUS = 'Решение глобальных вопросов с глобальных позиций, помощь (влияние) Богов, вопросы религии в реальности социума.'
export const ANSUZ_MINUS =
  // eslint-disable-next-line max-len
  'Эгоистичные поступки, в том числе в решении глобальных вопросов, реализация только собственных интересов в реальности социума; отсутствие помощи Богов в реальности социума; «черная полоса» в жизни, проявленная в реальности социума.'

export const RAIDHO_PLUS =
  // eslint-disable-next-line max-len
  'Развитие в реальности социума; выражение пути в реальности социума, реальная настойчивость и стойкость в достижении цели в реальности социума; перемена мест, путешествия и поездки в реальности социума.'
export const RAIDHO_MINUS =
  // eslint-disable-next-line max-len
  'Отсутствие развития в реальности социума; отмена поездки и путешествия (или в локальном виде – переезда, поездки, путешествия), или неудачная поездка или путешествие в реальности социума; отсутствие деятельности в реальности социума.'

export const KENAZ_PLUS = 'Наличие информации, обучение в реальности социума, обретение опыта; проявление явного энтузиазма в реальности социума.'
export const KENAZ_MINUS = 'Отсутствие информации, непонимание ситуации и происходящего в реальности социума; отсутствие энтузиазма в реальности социума.'

export const GEBO_PLUS = 'Проявление в реальности социума любви или привязанности.'
export const GEBO_MINUS = 'Неприятие, ненависть, нежелание партнерства и отношений в реальности социума; жгучая ревность (уже с оттенком ненависти к предмету страсти) в реальности социума.'

export const WUNJO_PLUS = 'Удовольствия и радость в реальности социума.'
export const WUNJO_MINUS = 'Отсутствие радостных моментов в реальности социума, депрессия, грусть и печаль в реальности социума.'

export const HAGALAZ_PLUS = 'Уничтожение устаревшего, вредного и ненужного, наведение порядка, отпор в реальности социума; проявление гнева в реальности социума.'
export const HAGALAZ_MINUS = 'Разрушение в реальности социума.'

export const NAUTHIZ_PLUS = 'Уничтожение устаревшего, вредного и ненужного, наведение порядка, отпор в реальности социума; проявление гнева в реальности социума.'
export const NAUTHIZ_MINUS = 'Разрушение в реальности социума.'

export const ISA_PLUS = 'Остановка, отдых, отсутствие реальных дел в реальности социума.'
export const ISA_MINUS = 'Метания, импульсные действия в реальности социума.'

export const JERA_PLUS = 'Размеренность, своевременность действий, получение или наличие урожая, результата в реальности социума; правильный ритм жизни, проявленный в реальности социума.'
export const JERA_MINUS =
  // eslint-disable-next-line max-len
  'Отсутствие урожая, результата, бесплодные действия в реальности социума; неправильный ритм жизни, проявленный в реальности социума; несвоевременные действия, спешка, или наоборот запаздывание в реальности социума. '

export const EIHWAZ_PLUS = 'Позитивные изменения в реальности социума.'
export const EIHWAZ_MINUS = 'Негативные изменения, смерть тех или других процессов в реальности социума.'

export const PERTHRO_PLUS = 'Реальное зарождение нового, рождение новых проектов в реальности социума; сексуальные процессы, вопросы, связанные с детьми в позитивном ключе в реальности социума. '
export const PERTHRO_MINUS =
  'Отказ от новых жизненных тенденций, проектов и так далее, отказ или отсутствие секса (половой жизни) в реальности социума; отсутствие детей или проблемы с ними в реальности социума.'

export const ALGIZ_PLUS = 'Наличие защиты или предоставление защиты кому-либо в реальности социума.'
export const ALGIZ_MINUS = 'Опасность, отсутствие защиты в реальности социума.'

export const SOWILO_PLUS = 'Успех в реальности социума.'
export const SOWILO_MINUS = 'Неудача, поражение, проигрыш в реальности социума.'

export const TIWAZ_PLUS = 'Стойкость на жизненном пути, проявленная в реальности социума, скрытая или явная война в реальности социума с возможностью победить в этой войне.'
export const TIWAZ_MINUS = 'Проявление трусости в реальности, сдача на милость победителя, поражение в реальности социума; неблагородные и неблаговидные поступки и действия в реальности социума.'

export const BERKANO_PLUS =
  'Проявление в реальности социума мягкости, способности сопереживать; выражение доброты, материнских (родительских) качеств в реальности социума; польза от какой-то женщины в реальности социума.'
export const BERKANO_MINUS = 'Проявление в реальности социума истеричности, непримиримости, конфликтности; вред от какой-то женщины в реальности социума.'

export const EHWAZ_PLUS = 'Факт реализации намерения, магических способностей, осуществление магических процессов в реальности социума.'
export const EHWAZ_MINUS =
  // eslint-disable-next-line max-len
  'Нахождение под чьим-то управлением в реальности социума; неспособность в реальности социума выразить и отстоять свое мнение, нахождение в состоянии зомби; магическое воздействие, выраженное в реальности социума и направленное на рассматриваемый объект. '

export const MANNAZ_PLUS =
  'Реализация творчества, реализация способностей, воплощение замыслов в реальности социума, проявление Эго в реальности социума; помощь, польза от некого человека в реальности социума.'
export const MANNAZ_MINUS =
  // eslint-disable-next-line max-len
  'Отказ от собственных интересов в реальности социума; вред от некого человека в реальности социума (речь идет в первую очередь о мужчине, но речь также может идти и о сильной, деловой женщине в мужской ипостаси).'

export const LAGUZ_PLUS = 'Игра, интриги, выход из той или другой ситуации в реальности социума, адаптация к существующей реальности, встраивание в существующую реальность.'
export const LAGUZ_MINUS =
  // eslint-disable-next-line max-len
  'Нарушение хода процессов в реальности социума; конкретная неспособность преодолеть препятствия, адаптироваться к существующей реальности и приспособиться к жизни; «несгибаемость», неразумная принципиальность, стремление войти в противоречие с людьми и обстоятельствами, выраженные в реальности социума.'

export const INGWAZ_PLUS = 'Изобилие по жизни, потенция, жизненная сила и энергия в реальности социума.'
export const INGWAZ_MINUS = 'Отсутствие изобилия по жизни, реальные проблемы с жизненной силой и энергией в реальности социума; проявление жадности и скупости в реальности социума.'

export const OTHALA_PLUS = 'Факт семьи или отношений, вопросы семьи или отношений, создание семьи или формирование отношений в реальности социума.'
export const OTHALA_MINUS = 'Одиночество и замыкание в этом одиночестве в реальности социума; разрушение семьи, отношений, выход из коллектива в реальности социума; увольнение в реальности социума.'

export const DAGAZ_PLUS = 'Завершение дел и процессов, выход на следующий цикл, а иногда и на следующий круг развития в реальности социума.'
export const DAGAZ_MINUS =
  // eslint-disable-next-line max-len
  'Отсутствие в реальности социума завершения какого-либо процесса, невозможность перейти на следующий круг развития в реальности социума; невозможность достичь чего-либо в реальности социума; отсутствие результата в реальности социума.'
