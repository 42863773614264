import './index.css'

import App from '@my-app/app'
import DS from 'assets/fonts/DS/DS-Note.ttf'
import Exo2 from 'assets/fonts/Exo2/Exo2-Regular.ttf'
import { AppRegistry } from 'react-native'
import iconFontAw from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import iconFontM from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import iconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'

import reportWebVitals from './reportWebVitals'

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialIcons;
}`

const iconFontStylesM = `@font-face {
  src: url(${iconFontM});
  font-family: MaterialCommunityIcons;
}`

const iconFontStylesAw = `@font-face {
  src: url(${iconFontAw});
  font-family: FontAwesome;
}`

const iconFontStylesE = `@font-face {
  src: url(${Exo2});
  font-family: Exo2;
}`

const iconFontStylesDS = `@font-face {
  src: url(${DS});
  font-family: 'DS Note';
}`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
  style.appendChild(document.createTextNode(iconFontStylesM))
  style.appendChild(document.createTextNode(iconFontStylesAw))
  style.appendChild(document.createTextNode(iconFontStylesE))
  style.appendChild(document.createTextNode(iconFontStylesDS))
}

// Inject stylesheet
document.head.appendChild(style)

AppRegistry.registerComponent('App', () => App)

AppRegistry.runApplication('App', {
  initialProps: {},
  rootTag: document.getElementById('root')
})

reportWebVitals()
