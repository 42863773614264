import { useNavigation } from '@react-navigation/native'
import { Dialog } from '@rneui/themed'
import RuneComponent from 'components/rune'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { ActivityIndicator, FlatList, Image, Text, useWindowDimensions, View } from 'react-native'
import { useSelector } from 'react-redux'
// @ts-ignore
import R from 'res'
import { SCREEN_RESULT } from 'res/screens'
import { RootState } from 'store/store'
import { IRune } from 'types/components'
import { calculationRunes, getRandomInt, shuffle } from 'utils/utils'

import styles from './BlockRunes.styles'

const runesArrayImg = R.images

const BlockRunes = ({ currentPage, setCurrentPage, setVisible }: { currentPage: number; setCurrentPage: (currentPage: number) => void; setVisible: (visible: boolean) => void }) => {
  const navigation = useNavigation()
  const counter = useSelector((state: RootState) => state.counter.prediction)
  const [runesImg, setRunesImg] = useState<IRune[]>([])
  const [isLoading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const { width, height } = useWindowDimensions()

  const [visibleDialog, setVisibleDialog] = useState(false)
  const dialogMessage = 'Эту руну выбрать нельзя!'

  useEffect(() => {
    if (count === (runesImg.length > 4 ? 4 : runesImg.length === 0 ? 4 : runesImg.length)) {
      setTimeout(() => {
        setLoading(true)
        setCurrentPage(currentPage + 1)
        setCount(0)
      }, 500)
    }
  }, [count])

  useEffect(() => {
    if (currentPage === 11) {
      // @ts-ignore
      navigation.navigate(SCREEN_RESULT)
      setCurrentPage(1)
      setVisible(true)
    }

    if (currentPage <= 6) {
      setRunesImg(shuffle(runesArrayImg['IMG_RUNES_' + currentPage]))
    } else {
      const current = counter[counter.length - 1].steps
      const arrImg = []

      if (currentPage === 7) {
        for (let i = 0; i < 8; i++) {
          arrImg.push(current[i])
        }
      } else if (currentPage === 8) {
        for (let i = 8; i < 16; i++) {
          arrImg.push(current[i])
        }
      } else if (currentPage === 9) {
        for (let i = 16; i < 24; i++) {
          arrImg.push(current[i])
        }
      } else if (currentPage === 10) {
        const viewResult = calculationRunes(counter, 10)

        // @ts-ignore
        for (let i = 0; i < viewResult.view.length; i++) {
          // @ts-ignore
          arrImg.push(viewResult.view[i])
        }
      }

      setRunesImg(shuffle(arrImg))
    }
    setLoading(false)
  }, [currentPage])

  const isLandscape = () => {
    return isMobile && width > height
  }

  const renderItems = (item: IRune, index: number, randomIndex1: number, randomIndex2: number, randomIndex3: number) => {
    let notPress = false

    if (currentPage <= 9) {
      notPress = index === randomIndex1 || index === randomIndex2 || index === randomIndex3
    }

    return (
      <RuneComponent key={index.toString()} count={count} setCount={setCount} item={item} currentPage={currentPage} notPress={notPress} setVisible={setVisibleDialog}>
        <Image source={item.img} style={isMobile ? styles.runeImgMobile : styles.runeImg} />
      </RuneComponent>
    )
  }

  const renderRunesFlatList = () => {
    let randomIndex1 = getRandomInt(0, 8)
    let randomIndex2 = getRandomInt(0, 8)
    let randomIndex3 = getRandomInt(0, 8)

    if (randomIndex1 === randomIndex2) {
      randomIndex2 = getRandomInt(0, 8)
    }

    if (randomIndex2 === randomIndex3) {
      randomIndex3 = getRandomInt(0, 8)
    }

    if (randomIndex1 === randomIndex3) {
      randomIndex3 = getRandomInt(0, 8)
    }

    return (
      <FlatList
        columnWrapperStyle={{ flexWrap: 'wrap' }}
        numColumns={isLandscape() ? 4 : 8}
        data={runesImg}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }: { item: IRune; index: number }) => renderItems(item, index, randomIndex1, randomIndex2, randomIndex3)}
      />
    )
  }

  const getBlockRunes = () => {
    if (isMobile && isLandscape()) {
      return [styles.viewRunesMobile]
    } else if (!isMobile) {
      return [styles.viewRunes]
    }
  }

  const onBackdropPress = () => {
    setVisibleDialog(false)
  }

  return (
    <View style={getBlockRunes()}>
      {isLoading ? <ActivityIndicator /> : renderRunesFlatList()}
      <Dialog isVisible={visibleDialog} onBackdropPress={onBackdropPress} overlayStyle={{ width: 500, height: 200 }}>
        <Dialog.Title title="ВЫБОР РУНЫ" titleStyle={{ color: '#8c8c8c' }} />
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 16, color: 'red' }}>{dialogMessage}</Text>
        </View>
        <Dialog.Actions>
          <Dialog.Button title="Закрыть" onPress={onBackdropPress} />
        </Dialog.Actions>
      </Dialog>
    </View>
  )
}

export default BlockRunes
