import React from 'react'
import Svg, { Path } from 'react-native-svg'

const MenuSvg = ({ width, height, fill }: any) => {
  return (
    <Svg width={`${width}`} height={`${height}`} fill={`${fill}`} viewBox="0 0 496 336">
      <Path d="M472.8,48 L23.2,48 C10.4,48 0,36.8 0,24 C0,11.2 10.4,0 23.2,0 L472.8,0 C485.6,0 496,11.2 496,24 C496,36.8 485.6,48 472.8,48 Z" id="Path" />
      <Path d="M472.8,192 L23.2,192 C10.4,192 0,180.8 0,168 C0,155.2 10.4,144 23.2,144 L472.8,144 C485.6,144 496,155.2 496,168 C496,180.8 485.6,192 472.8,192 Z" id="Path" />
      <Path d="M472.8,336 L23.2,336 C10.4,336 0,324.8 0,312 C0,299.2 10.4,288 23.2,288 L472.8,288 C485.6,288 496,299.2 496,312 C496,324.8 485.6,336 472.8,336 Z" id="Path" />
      <Path d="M392.8,288 L284.8,288 L331.2,336 L392,336 C404.8,336 415.2,324.8 415.2,312 C416,299.2 405.6,288 392.8,288 Z" id="Path" />
      <Path d="M392.8,144 L142.4,144 L188.8,192 L392,192 C404.8,192 415.2,180.8 415.2,168 C415.2,155.2 405.6,144 392.8,144 Z" id="Path" />
      <Path d="M392.8,0 L0,0 L46.4,48 L392,48 C404.8,48 415.2,36.8 415.2,24 C416,11.2 405.6,0 392.8,0 Z" id="Path" />
    </Svg>
  )
}

export default MenuSvg
