import { ScaledSheet } from 'react-native-size-matters/extend'

export default ScaledSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingVertical: 10
  },
  fadingContainer: {
    backgroundColor: '#15aedc',
    width: 150,
    height: 207,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  fadingContainerMobile: {
    backgroundColor: '#15aedc',
    width: '120@s',
    height: '166@s',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
