import { useStyles } from 'hooks/useStyles'
import React from 'react'
import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'

import stylesConfig from './Title.styles'

const Title = () => {
  const styles = useStyles(stylesConfig)
  const title = useSelector((state: RootState) => state.header.title)
  const subtitle = useSelector((state: RootState) => state.header.subtitle)

  if (!subtitle) {
    return (
      <View>
        <Text style={styles.title_null}>{title ? title : 'Загрузка...'}</Text>
      </View>
    )
  }

  return (
    <View>
      <Text style={styles.title}>{title ? title : 'Загрузка...'}</Text>
      {subtitle ? <Text style={styles.subtitle}>{subtitle}</Text> : null}
    </View>
  )
}

export default Title
