import { LinkingOptions, NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import Menu from 'components/header/menu'
import Right from 'components/header/right'
import Title from 'components/header/title'
import React from 'react'
import { Linking, Text } from 'react-native'
import { useDispatch } from 'react-redux'
import { SCREEN_ABOUT, SCREEN_HOME, SCREEN_MAIN, SCREEN_RESULT } from 'res/screens'
import { TTheme } from 'res/theme'
import HomeScreen from 'screens/home'
import MainScreen from 'screens/main'
import ResultScreen from 'screens/result'
import AboutScreen from 'screens/about'
import { setCurrentRouteName } from 'store/appRunes'

const MainNavigator = ({ theme }: { theme: TTheme }) => {
  const dispatch = useDispatch()
  const navigationRef = useNavigationContainerRef()
  const Stack = createNativeStackNavigator()
  const colors = theme.colors

  const config = {
    initialRouteName: 'Home',
    screens: {
      [SCREEN_HOME]: '/',
      [SCREEN_MAIN]: 'prediction',
      [SCREEN_RESULT]: 'result',
      [SCREEN_ABOUT]: 'about'
    }
  }

  const linking: LinkingOptions<any> = {
    prefixes: ['http://localhost:3000'],
    config,
    async getInitialURL() {
      return Linking.getInitialURL()
    }
  }

  return (
    <NavigationContainer
      onReady={() => {
        const currentRouteName = navigationRef.getCurrentRoute()?.name
        dispatch(setCurrentRouteName(currentRouteName))
      }}
      onStateChange={async () => {
        const currentRouteName = navigationRef.getCurrentRoute()?.name
        dispatch(setCurrentRouteName(currentRouteName))
      }}
      ref={navigationRef}
      linking={linking}
      fallback={<Text>Loading...</Text>}
      theme={theme}
    >
      <Stack.Navigator
        screenListeners={{
          state: () => {
            const currentRouteName = navigationRef.getCurrentRoute()?.name
            dispatch(setCurrentRouteName(currentRouteName))
          }
        }}
        screenOptions={{
          headerShown: true,
          headerStyle: {
            backgroundColor: colors.backgroundBody
          },
          animation: 'fade',
          headerLeft: () => <Menu />,
          headerTitle: () => <Title />,
          headerRight: () => <Right />
        }}
      >
        <Stack.Screen name={SCREEN_HOME} component={HomeScreen} />
        <Stack.Screen name={SCREEN_ABOUT} component={AboutScreen} />
        <Stack.Screen name={SCREEN_MAIN} component={MainScreen} options={{ headerShown: false }} />
        <Stack.Screen name={SCREEN_RESULT} component={ResultScreen} options={{ headerShown: false }} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default MainNavigator
