import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appRunes from './appRunes'
import counterReducer from './counterRunes'
import headerRunes from './headerRunes'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['header', 'counter']
}

export const rootReducer = combineReducers({
  counter: counterReducer,
  app: appRunes,
  header: headerRunes
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
