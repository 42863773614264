import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IAppState {
  isDarkMode: boolean
  currentRouteName: string | undefined
}

const initialState: IAppState = {
  isDarkMode: false,
  currentRouteName: undefined
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.isDarkMode = action.payload
    },
    setCurrentRouteName: (state, action: PayloadAction<string | undefined>) => {
      state.currentRouteName = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setDarkMode, setCurrentRouteName } = appSlice.actions

export default appSlice.reducer
