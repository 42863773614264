import { useNavigation } from '@react-navigation/native'
import { BackSvg, HomeSvg, MenuSvg } from 'assets/svg/icons'
import { useColors } from 'hooks/useColors'
import { useStyles } from 'hooks/useStyles'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { SCREEN_HOME, SCREEN_RESULT } from 'res/screens'
import { RootState } from 'store/store'

import stylesConfig from './Menu.styles'

const Menu = () => {
  const styles = useStyles(stylesConfig)
  const navigation = useNavigation()
  const colors = useColors()
  const currentRouteName = useSelector((state: RootState) => state.app.currentRouteName)

  const Image = () => {
    switch (currentRouteName) {
      case SCREEN_HOME:
        return <MenuSvg width={26} height={26} fill={colors.logo} />
      case SCREEN_RESULT:
        return <HomeSvg width={26} height={26} fill={colors.logo} />
      default:
        return <BackSvg width={26} height={26} fill={colors.logo} />
    }
  }

  const fnImage = () => {
    switch (currentRouteName) {
      case SCREEN_HOME:
        // @ts-ignore
        navigation.openDrawer()
        break
      case SCREEN_RESULT:
        // @ts-ignore
        navigation.replace(SCREEN_HOME)
        break
      default:
        // @ts-ignore
        navigation.canGoBack() ? navigation.goBack() : global.runes.fnBack()
        break
    }
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => fnImage()}>{Image()}</TouchableOpacity>
    </View>
  )
}

export default Menu
