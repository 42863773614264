import { createStyles } from 'utils/createStyles'
import {Dimensions} from "react-native";

export default createStyles((colors) => ({
  container: {
    width: '100vw'
    // height: '100vh'
  },
  viewSteps: {
    paddingTop: 20
  },
  containerStyle: {
    width: '40vw',
    height: '20vh',
    backgroundColor: 'white',
    padding: 20
  },
  dialogStyle: {
    width: '300@s',
    height: '200@s',
    alignSelf: 'center',
    backgroundColor: colors.background,
    borderWidth: 1,
    borderColor: colors.dialog,
    borderRadius: 10
  },
  dialogStyleMobile: {
    alignSelf: 'center',
    backgroundColor: colors.background,
    borderWidth: 1,
    borderColor: colors.dialog,
    borderRadius: 10
  },
  dialogStyleMobileLandscape: {
    width: Dimensions.get('window').width - 50 + '@s',
    alignSelf: 'center',
    backgroundColor: colors.background,
    borderWidth: 1,
    borderColor: colors.dialog,
    borderRadius: 10
  },
  contentStyle: {
    flex: 1
  },
  buttonStyle: {
    borderWidth: 1,
    borderColor: 'red'
  },
  buttonTextOk: {
    color: colors.logo
  },
  buttonText: {
    color: 'red'
  },
  actions: {
    borderTopWidth: 1,
    borderTopColor: colors.dialog
  },
  text: {
    fontFamily: 'Exo2',
    fontSize: 16,
    color: colors.text,
    paddingBottom: 10
  }
}))
