import { Platform } from 'react-native'
import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  menu: Platform.select({
    web: {
      color: colors.text
    },
    ios: {
      color: colors.text
    }
  }),
  leftIcon: Platform.select({
    web: {
      paddingTop: 5,
      paddingLeft: 10
    },
    ios: {
      paddingTop: 5,
      paddingLeft: 10
    }
  }),
  title: Platform.select({
    web: {
      fontFamily: 'Exo2',
      fontSize: 18,
      fontWeight: 'bold',
      paddingLeft: 10
    },
    ios: {
      fontFamily: 'Exo 2',
      fontSize: 18,
      fontWeight: 'bold',
      paddingLeft: 10
    }
  })
}))
