import { useStyles } from 'hooks/useStyles'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Image, Linking, Text, TouchableOpacity, View } from 'react-native'

import stylesConfig from './Footer.styles'

const Footer = () => {
  const styles = useStyles(stylesConfig)

  if (isMobile) {
    return (
      <>
        <View style={styles.rowMobile}>
          <Text style={styles.copyright}>Алексей Золотарёв, разработчик</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.copyright}>Денис Артемьев </Text>
            <TouchableOpacity onPress={() => Linking.openURL('https://dennart.ru')} style={styles.link}>
              <Text style={styles.link}>(Мастерская визуальных продуктов)</Text>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.copyright}>Олег Шапошников, автор портала </Text>
            <TouchableOpacity onPress={() => Linking.openURL('http://runa-odin.org')} style={styles.link}>
              <Text style={styles.link}>Руны Одина</Text>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: 'row', paddingTop: 3 }}>
            <TouchableOpacity onPress={() => Linking.openURL('http://www.runa-odin.org')} style={{ paddingRight: 10 }}>
              <Image source={require('assets/images/logo.jpg')} style={{ width: 30, height: 30, borderRadius: 5 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('https://vk.com/runaodin')} style={{ paddingRight: 10 }}>
              <Image source={require('assets/images/vk.png')} style={{ width: 30, height: 30 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('https://t.me/runes_of_odin')} style={{ paddingRight: 10 }}>
              <Image source={require('assets/images/tel.png')} style={{ width: 30, height: 30 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('https://zen.yandex.ru/id/622356237d728d3bad930c2a')} style={{ paddingRight: 0 }}>
              <Image source={require('assets/images/dzen.png')} style={{ width: 30, height: 30 }} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.bottom}>
          <Text style={styles.copyright}>© Все права защищены</Text>
        </View>
      </>
    )
  }

  return (
    <>
      <View style={styles.row}>
        <View style={styles.left}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.copyright}>Олег Шапошников, автор портала </Text>
            <TouchableOpacity onPress={() => Linking.openURL('http://runa-odin.org')} style={styles.link}>
              <Text style={styles.link}>Руны Одина</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.copyright}>Алексей Золотарёв, разработчик</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.copyright}>Денис Артемьев </Text>
            <TouchableOpacity onPress={() => Linking.openURL('https://dennart.ru')} style={styles.link}>
              <Text style={styles.link}>(Мастерская визуальных продуктов)</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.right}>
          <View style={{ flexDirection: 'row', paddingTop: 3 }}>
            <TouchableOpacity onPress={() => Linking.openURL('http://www.runa-odin.org')} style={{ paddingRight: 10 }}>
              <Image source={require('assets/images/logo.jpg')} style={{ width: 30, height: 30, borderRadius: 5 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('https://vk.com/runaodin')} style={{ paddingRight: 10 }}>
              <Image source={require('assets/images/vk.png')} style={{ width: 30, height: 30 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('https://t.me/runes_of_odin')} style={{ paddingRight: 10 }}>
              <Image source={require('assets/images/tel.png')} style={{ width: 30, height: 30 }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('https://zen.yandex.ru/id/622356237d728d3bad930c2a')} style={{ paddingRight: 0 }}>
              <Image source={require('assets/images/dzen.png')} style={{ width: 30, height: 30 }} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View style={styles.bottom}>
        <Text style={styles.copyright}>© Все права защищены</Text>
      </View>
    </>
  )
}

export default Footer
