import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  container: {
    width: '100vw',
    // height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background
  },
  viewRunes: {
    paddingTop: 120,
    flexDirection: 'row',
    alignSelf: 'center'
  },
  viewRunesMobile: {
    paddingTop: 10,
    // flexDirection: 'row',
    alignSelf: 'center'
  },
  fadingContainer: {
    backgroundColor: '#15aedc',
    width: 100,
    height: 200,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20
  },
  fadingContainerTest: {
    backgroundColor: '#15aedc',
    width: 50,
    height: 100,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20
  },
  runeImg: {
    width: 150,
    height: 207,
    borderRadius: 10,
    marginHorizontal: 10
  },
  nullResult: {
    color: colors.text,
    fontFamily: 'Exo2',
    fontSize: 20,
    fontWeight: 'bold'
  },
  description: {
    color: colors.text,
    fontFamily: 'Exo2',
    fontSize: 18,
    marginHorizontal: 20,
    paddingTop: 20
  },
  descriptionRune: {
    color: colors.text,
    fontFamily: 'Exo2',
    fontSize: 18,
    marginHorizontal: 20,
    paddingTop: 20,
    fontWeight: 'bold'
  },
  button: {
    width: 200,
    backgroundColor: colors.background,
    alignSelf: 'center',
    marginTop: 40,
    borderWidth: 1,
    borderColor: colors.text
  },
  buttonText: {
    fontSize: 16,
    color: colors.text
  }
}))
