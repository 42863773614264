import { ScaledSheet } from 'react-native-size-matters/extend'

export default ScaledSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  viewRunes: {
    paddingTop: 120,
    flexDirection: 'row',
    alignSelf: 'center'
  },
  viewRunesMobile: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  runeImg: {
    width: 150,
    height: 207,
    borderRadius: 10
  },
  runeImgMobile: {
    width: '120@s',
    height: '166@s',
    borderRadius: 10
  },
  fadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10
  }
})
