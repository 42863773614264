import { Platform } from 'react-native'
import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  text: Platform.select({
    web: {
      fontFamily: 'Exo2',
      fontSize: 18,
      fontWeight: 'bold'
    },
    ios: {
      fontFamily: 'Exo 2',
      fontSize: 18,
      fontWeight: 'bold'
    }
  }),
  description: Platform.select({
    web: {
      fontFamily: 'Exo2',
      fontSize: 16,
      paddingRight: 5
    },
    ios: {
      fontFamily: 'Exo 2',
      fontSize: 16,
      paddingRight: 5
    }
  })
}))
