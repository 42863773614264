import MainNavigator from 'navigator/MainNavigator'
import React from 'react'
import { Platform, useColorScheme } from 'react-native'
import { Portal, Provider } from 'react-native-paper'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import theme from 'res/theme'
// @ts-ignore
import { RootState } from 'store/store'

const AppMain = () => {
  const isDarkMode: boolean = useColorScheme() === 'dark'
  const isDarkModeWeb = useSelector((state: RootState) => state.app.isDarkMode)

  const getTheme = () => {
    if (Platform.OS === 'web') {
      return isDarkModeWeb ? theme.dark : theme.light
    } else {
      return isDarkMode ? theme.dark : theme.light
    }
  }

  const bg = isDarkMode ? theme.dark.colors.background : theme.light.colors.background
  const WebSafeArea = ({ children }: any) => {
    if (Platform.OS === 'web') {
      return (
        <SafeAreaView
          mode={'margin'}
          style={{
            width: '100vw',
            height: '100vh'
          }}
        >
          {children}
        </SafeAreaView>
      )
    } else {
      return <>{children}</>
    }
  }
  return (
    <SafeAreaProvider style={{ backgroundColor: bg }}>
      <WebSafeArea>
        <Provider>
          <Portal>
            <MainNavigator theme={getTheme()} />
          </Portal>
        </Provider>
      </WebSafeArea>
    </SafeAreaProvider>
  )
}

export default AppMain
