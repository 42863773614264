// @ts-ignore
import algiz_minus from 'assets/runes/minus/algiz.jpg'
// @ts-ignore
import ansuz_minus from 'assets/runes/minus/ansuz.jpg'
// @ts-ignore
import berkano_minus from 'assets/runes/minus/berkano.jpg'
// @ts-ignore
import dagaz_minus from 'assets/runes/minus/dagaz.jpg'
// @ts-ignore
import ehwaz_minus from 'assets/runes/minus/ehwaz.jpg'
// @ts-ignore
import eihwaz_minus from 'assets/runes/minus/eihwaz.jpg'
// @ts-ignore
import fehu_minus from 'assets/runes/minus/fehu.jpg'
// @ts-ignore
import gebo_minus from 'assets/runes/minus/gebo.jpg'
// @ts-ignore
import hagalaz_minus from 'assets/runes/minus/hagalaz.jpg'
// @ts-ignore
import ingwaz_minus from 'assets/runes/minus/ingwaz.jpg'
// @ts-ignore
import isa_minus from 'assets/runes/minus/isa.jpg'
// @ts-ignore
import jera_minus from 'assets/runes/minus/jera.jpg'
// @ts-ignore
import kenaz_minus from 'assets/runes/minus/kenaz.jpg'
// @ts-ignore
import laguz_minus from 'assets/runes/minus/laguz.jpg'
// @ts-ignore
import mannaz_minus from 'assets/runes/minus/mannaz.jpg'
// @ts-ignore
import nauthiz_minus from 'assets/runes/minus/nauthiz.jpg'
// @ts-ignore
import othala_minus from 'assets/runes/minus/othala.jpg'
// @ts-ignore
import perthro_minus from 'assets/runes/minus/perthro.jpg'
// @ts-ignore
import raidho_minus from 'assets/runes/minus/raidho.jpg'
// @ts-ignore
import sowilo_minus from 'assets/runes/minus/sowilo.jpg'
// @ts-ignore
import thurisaz_minus from 'assets/runes/minus/thurisaz.jpg'
// @ts-ignore
import tiwaz_minus from 'assets/runes/minus/tiwaz.jpg'
// @ts-ignore
import uruz_minus from 'assets/runes/minus/uruz.jpg'
// @ts-ignore
import wunjo_minus from 'assets/runes/minus/wunjo.jpg'
// @ts-ignore
import algiz_plus from 'assets/runes/plus/algiz.jpg'
// @ts-ignore
import ansuz_plus from 'assets/runes/plus/ansuz.jpg'
// @ts-ignore
import berkano_plus from 'assets/runes/plus/berkano.jpg'
// @ts-ignore
import dagaz_plus from 'assets/runes/plus/dagaz.jpg'
// @ts-ignore
import ehwaz_plus from 'assets/runes/plus/ehwaz.jpg'
// @ts-ignore
import eihwaz_plus from 'assets/runes/plus/eihwaz.jpg'
// @ts-ignore
import fehu_plus from 'assets/runes/plus/fehu.jpg'
// @ts-ignore
import gebo_plus from 'assets/runes/plus/gebo.jpg'
// @ts-ignore
import hagalaz_plus from 'assets/runes/plus/hagalaz.jpg'
// @ts-ignore
import ingwaz_plus from 'assets/runes/plus/ingwaz.jpg'
// @ts-ignore
import isa_plus from 'assets/runes/plus/isa.jpg'
// @ts-ignore
import jera_plus from 'assets/runes/plus/jera.jpg'
// @ts-ignore
import kenaz_plus from 'assets/runes/plus/kenaz.jpg'
// @ts-ignore
import laguz_plus from 'assets/runes/plus/laguz.jpg'
// @ts-ignore
import mannaz_plus from 'assets/runes/plus/mannaz.jpg'
// @ts-ignore
import nauthiz_plus from 'assets/runes/plus/nauthiz.jpg'
// @ts-ignore
import othala_plus from 'assets/runes/plus/othala.jpg'
// @ts-ignore
import perthro_plus from 'assets/runes/plus/perthro.jpg'
// @ts-ignore
import raidho_plus from 'assets/runes/plus/raidho.jpg'
// @ts-ignore
import sowilo_plus from 'assets/runes/plus/sowilo.jpg'
// @ts-ignore
import thurisaz_plus from 'assets/runes/plus/thurisaz.jpg'
// @ts-ignore
import tiwaz_plus from 'assets/runes/plus/tiwaz.jpg'
// @ts-ignore
import uruz_plus from 'assets/runes/plus/uruz.jpg'
// @ts-ignore
import wunjo_plus from 'assets/runes/plus/wunjo.jpg'
// @ts-ignore
import * as D from 'res/description'

export const IMG_RUNES_1 = [
  { name: 'Fehu+', weight: 0, img: fehu_plus, description: D.FEHU_PLUS },
  { name: 'Fehu-', weight: 0, img: fehu_minus, description: D.FEHU_MINUS },
  { name: 'Uruz+', weight: 0, img: uruz_plus, description: D.URUZ_PLUS },
  { name: 'Uruz-', weight: 0, img: uruz_minus, description: D.URUZ_MINUS },
  { name: 'Thurisaz+', weight: 0, img: thurisaz_plus, description: D.THURISAZ_PLUS },
  { name: 'Thurisaz-', weight: 0, img: thurisaz_minus, description: D.THURISAZ_MINUS },
  { name: 'Ansuz+', weight: 0, img: ansuz_plus, description: D.ANSUZ_PLUS },
  { name: 'Ansuz-', weight: 0, img: ansuz_minus, description: D.ANSUZ_MINUS }
]
export const IMG_RUNES_2 = [
  { name: 'Raidho+', weight: 0, img: raidho_plus, description: D.RAIDHO_PLUS },
  { name: 'Raidho-', weight: 0, img: raidho_minus, description: D.RAIDHO_MINUS },
  { name: 'Kenaz+', weight: 0, img: kenaz_plus, description: D.KENAZ_PLUS },
  { name: 'Kenaz-', weight: 0, img: kenaz_minus, description: D.KENAZ_MINUS },
  { name: 'Gebo+', weight: 0, img: gebo_plus, description: D.GEBO_PLUS },
  { name: 'Gebo-', weight: 0, img: gebo_minus, description: D.GEBO_MINUS },
  { name: 'Wunjo+', weight: 0, img: wunjo_plus, description: D.WUNJO_PLUS },
  { name: 'Wunjo-', weight: 0, img: wunjo_minus, description: D.WUNJO_MINUS }
]
export const IMG_RUNES_3 = [
  { name: 'Hagalaz+', weight: 0, img: hagalaz_plus, description: D.HAGALAZ_PLUS },
  { name: 'Hagalaz-', weight: 0, img: hagalaz_minus, description: D.HAGALAZ_MINUS },
  { name: 'Nauthiz+', weight: 0, img: nauthiz_plus, description: D.NAUTHIZ_PLUS },
  { name: 'Nauthiz-', weight: 0, img: nauthiz_minus, description: D.NAUTHIZ_MINUS },
  { name: 'Isa+', weight: 0, img: isa_plus, description: D.ISA_PLUS },
  { name: 'Isa-', weight: 0, img: isa_minus, description: D.ISA_MINUS },
  { name: 'Jera+', weight: 0, img: jera_plus, description: D.JERA_PLUS },
  { name: 'Jera-', weight: 0, img: jera_minus, description: D.JERA_MINUS }
]
export const IMG_RUNES_4 = [
  { name: 'Eihwaz+', weight: 0, img: eihwaz_plus, description: D.EIHWAZ_PLUS },
  { name: 'Eihwaz-', weight: 0, img: eihwaz_minus, description: D.EIHWAZ_MINUS },
  { name: 'Perthro+', weight: 0, img: perthro_plus, description: D.PERTHRO_PLUS },
  { name: 'Perthro-', weight: 0, img: perthro_minus, description: D.PERTHRO_MINUS },
  { name: 'Algiz+', weight: 0, img: algiz_plus, description: D.ALGIZ_PLUS },
  { name: 'Algiz-', weight: 0, img: algiz_minus, description: D.ALGIZ_MINUS },
  { name: 'Sowilo+', weight: 0, img: sowilo_plus, description: D.SOWILO_PLUS },
  { name: 'Sowilo-', weight: 0, img: sowilo_minus, description: D.SOWILO_MINUS }
]
export const IMG_RUNES_5 = [
  { name: 'Tiwaz+', weight: 0, img: tiwaz_plus, description: D.TIWAZ_PLUS },
  { name: 'Tiwaz-', weight: 0, img: tiwaz_minus, description: D.TIWAZ_MINUS },
  { name: 'Berkano+', weight: 0, img: berkano_plus, description: D.BERKANO_PLUS },
  { name: 'Berkano-', weight: 0, img: berkano_minus, description: D.BERKANO_MINUS },
  { name: 'Ehwaz+', weight: 0, img: ehwaz_plus, description: D.EHWAZ_PLUS },
  { name: 'Ehwaz-', weight: 0, img: ehwaz_minus, description: D.EHWAZ_MINUS },
  { name: 'Mannaz+', weight: 0, img: mannaz_plus, description: D.MANNAZ_PLUS },
  { name: 'Mannaz-', weight: 0, img: mannaz_minus, description: D.MANNAZ_MINUS }
]
export const IMG_RUNES_6 = [
  { name: 'Laguz+', weight: 0, img: laguz_plus, description: D.LAGUZ_PLUS },
  { name: 'Laguz-', weight: 0, img: laguz_minus, description: D.LAGUZ_MINUS },
  { name: 'Ingwaz+', weight: 0, img: ingwaz_plus, description: D.INGWAZ_PLUS },
  { name: 'Ingwaz-', weight: 0, img: ingwaz_minus, description: D.INGWAZ_MINUS },
  { name: 'Othala+', weight: 0, img: othala_plus, description: D.OTHALA_PLUS },
  { name: 'Othala-', weight: 0, img: othala_minus, description: D.OTHALA_MINUS },
  { name: 'Dagaz+', weight: 0, img: dagaz_plus, description: D.DAGAZ_PLUS },
  { name: 'Dagaz-', weight: 0, img: dagaz_minus, description: D.DAGAZ_MINUS }
]
