import { Platform } from 'react-native'
import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  container: Platform.select({
    web: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 20
    },
    ios: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }),
  contentMenu: Platform.select({
    web: {
      backgroundColor: colors.background
    },
    ios: {
      backgroundColor: colors.background
    }
  }),
  styleMenu: Platform.select({
    web: {
      marginTop: 36,
      marginLeft: 10
    },
    ios: {
      marginTop: 30,
      marginLeft: 10,
      width: 300
    }
  })
}))
