import { useNavigation } from '@react-navigation/native'
import { useColors } from 'hooks/useColors'
import { useStyles } from 'hooks/useStyles'
import React, { useEffect, useLayoutEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Linking, ScrollView, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
// @ts-ignore
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { WIDTH_CONTEXT } from 'res/consts'
import { SCREEN_HOME } from 'res/screens'
import { setTitle } from 'store/headerRunes'
import { setGlobalFn } from 'utils/utils'

import stylesConfig from './AboutScreen.styles'

const AboutScreen = () => {
  const styles = useStyles(stylesConfig)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const colors = useColors()
  const { width, height } = useWindowDimensions()

  const backToHome = () => {
    // @ts-ignore
    navigation.replace(SCREEN_HOME, {})
  }

  useLayoutEffect(() => {
    if (!navigation.canGoBack()) {
      setGlobalFn(backToHome)
    }

    return () => setGlobalFn(undefined)
  }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(setTitle('О ПРОЕКТЕ'))
      document.title = 'О ПРОЕКТЕ'
    })

    return unsubscribe
  }, [navigation])

  const browser = () => {
    if (isMobile) {
      return { width: width - 16 }
    }
    return { marginHorizontal: width < WIDTH_CONTEXT ? 20 : 0, width: width < WIDTH_CONTEXT ? width - 40 : WIDTH_CONTEXT }
  }

  return (
    <ScrollView contentContainerStyle={[styles.centerView, browser()]}>
      <View style={{ height: 20 }} />
      <Text style={styles.text}>
        {
          // eslint-disable-next-line max-len
          'Перед Вами Рунический Оракул CR. Вы мысленно задаете определенный вопрос. Его надо правильно сформулировать (как говорил один мой знакомый: правильно заданный вопрос – это уже полдела). Этот вопрос может касаться лично Вас, или какого-то события, связанного с Вами. Сформулировали? Теперь расслабьтесь и, держа в голове заданный вопрос, начинайте выбирать Руны.'
        }
      </Text>
      <View style={{ height: 20 }} />
      <Text style={styles.text}>
        {
          // eslint-disable-next-line max-len
          'Не нужно искать какие-то смыслы, или выстраивать какую-то стратегию. Просто выбирайте то, что Вам сейчас хочется выбрать. Инструкция по выбору будет показана при начале работы. Оракул, как нейросеть, вступает с Вами в диалог. Вы делаете выбор, а Оракул может позволить Вам сделать этот выбор, или, наоборот, запретить его. Если выбор той или другой Руны был запрещен на момент выбора, то после состоявшегося выбора другой Руны, этот запрет может быть снят. По окончанию всех шагов выбора Вам будет показан результат.'
        }
      </Text>
      <View style={{ height: 20 }} />
      <Text style={styles.text}>
        {
          // eslint-disable-next-line max-len
          'Учитывая разный уровень рунических знаний пользователей, неизбежно разный подход к тестированию при разном уровне этих знаний, а также, неизбежную адаптацию к работе с Оракулом, которая в данном случае сказывается отрицательно, введены три уровня Оракула. Первый уровень – начальный, максимально облегченный, для любых пользователей, даже совершенно не знакомых с Рунами. Второй уровень, - средний, обычный, подходит для тех, кто систематически и регулярно пользуется Оракулом и обладает определенными знаниями о Рунах. Третий уровень – сложный, подходит для тех, кто пользуется Оракулом в профессиональных целях, и очень хорошо знаком с Рунами. Представленная в данный момент версия является Оракулом первого уровня.'
        }
      </Text>
      <View style={{ height: 20 }} />
      <Text style={styles.text}>
        {
          'Демоверсия Оракула позволяет получить картину по Мидгарду Рунического Древа, показывая социальную реальность и человека в этой социальной реальности.'
        }
      </Text>
      <View style={{ height: 20 }} />
      <Text style={styles.text}>
        {
          'Полнофункциональный Оракул (полнофункциональная версия программы) является платной.'
        }
      </Text>
      {/*<ReactPlayer url="http://lozohod.space/video.mp4" playing={true} controls={true} />*/}
    </ScrollView>
  )
}

export default AboutScreen
