import React, { useEffect } from 'react'
import { Platform, Text, UIManager } from 'react-native'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
// @ts-ignore
import { store } from 'store'
import { setCustomText } from 'utils/utils'

import AppMain from './App'

const App = () => {
  useEffect(() => {
    if (Platform.OS === 'android') {
      if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true)
      }
    }

    // @ts-ignore
    Text.defaultProps = Text.defaultProps || {}
    if (Platform.OS === 'web') {
      // @ts-ignore
      Text.defaultProps.style = { fontFamily: 'Exo2' }
    } else {
      const euclidTextProps = {
        style: {
          fontFamily: 'Exo 2'
        }
      }

      setCustomText(euclidTextProps)
    }
  }, [])

  // @ts-ignore
  global.runes = {}

  let persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppMain />
      </PersistGate>
    </Provider>
  )
}

export default App
