import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  container: {
    width: '100vw',
    height: '100vh'
  },
  centerView: {
    // width: '50vw',
    // height: '100vh',
    alignSelf: 'center',
    backgroundColor: colors.background
  },
  headerView: {
    alignItems: 'center',
    flex: 1
  },
  header: {
    fontFamily: 'DS Note',
    fontSize: 36,
    color: colors.logoText,
    fontWeight: 'bold',
    paddingLeft: 10
  },
  headerMobile: {
    fontFamily: 'DS Note',
    fontSize: '19@s',
    color: colors.logoText,
    fontWeight: 'bold',
    paddingLeft: 5
  },
  subHeader: {
    fontSize: 36,
    fontWeight: 'bold',
    fontFamily: 'DS Note',
    color: colors.text
  },
  subHeaderSub: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Exo2',
    color: colors.text
  },
  itemStyle: {
    backgroundColor: colors.listitem,
    borderBottomWidth: 0.5,
    borderRadius: 8,
    borderRightWidth: 0.5,
    marginVertical: 3
  },
  itemTitle: {
    fontSize: 18,
    fontFamily: 'Exo2',
    color: colors.text,
    fontWeight: 'bold'
  },
  itemSubtitle: {
    fontSize: 16,
    fontFamily: 'Exo2',
    color: colors.text
  },
  viewHeader: {
    flexDirection: 'row',
    backgroundColor: 'rgb(39, 28, 110)',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: colors.logoBorder,
    borderWidth: 1,
    marginTop: 20
  },
  viewHeaderMobile: {
    flexDirection: 'row',
    backgroundColor: 'rgb(39, 28, 110)',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: colors.logoBorder,
    borderWidth: 1
  },
  logo: {
    width: 100,
    height: 100,
    marginLeft: 5
  },
  logoMobile: {
    width: 60,
    height: 60
  },
  bottom: {
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // bottom: 0,
    borderWidth: 0,
    backgroundColor: 'transparent'
  },
  copyright: {
    fontSize: 14,
    fontFamily: 'Exo2',
    color: colors.text
  }
}))
