import { useNavigation } from '@react-navigation/native'
import { useStyles } from 'hooks/useStyles'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { FlatList, Image, Text, View } from 'react-native'
import { Button } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { SCREEN_HOME } from 'res/screens'
import { setTitle } from 'store/headerRunes'
import { RootState } from 'store/store'
// @ts-ignore
import { IRune } from 'types/components'
// @ts-ignore
import { lang } from 'utils/lang'
import { calculationRunes, setGlobalFn } from 'utils/utils'
import { isMobile } from 'react-device-detect'

import stylesConfig from './ResultScreen.styles'

const ResultScreen = () => {
  const styles = useStyles(stylesConfig)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const counter = useSelector((state: RootState) => state.counter.prediction)
  const [steps, setSteps] = useState<IRune[]>([])
  const [test, setTest] = useState<IRune[]>([])

  const backToHome = () => {
    // @ts-ignore
    navigation.replace(SCREEN_HOME, {})
  }

  useLayoutEffect(() => {
    if (!navigation.canGoBack()) {
      setGlobalFn(backToHome)
    }

    return () => setGlobalFn(undefined)
  }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(setTitle('РЕЗУЛЬТАТ'))
      document.title = 'РЕЗУЛЬТАТ'
    })

    return unsubscribe
  }, [navigation])

  useEffect(() => {
    if (counter.length > 0) {
      // @ts-ignore
      setSteps(calculationRunes(counter, 11).view)
      // @ts-ignore
      setTest(calculationRunes(counter, 10).test)
    }
  }, [counter])

  // const renderRunesFlatList = () => {
  //   return (
  //     <FlatList
  //       columnWrapperStyle={{ flexWrap: 'wrap' }}
  //       numColumns={8}
  //       data={steps}
  //       showsHorizontalScrollIndicator={false}
  //       keyExtractor={(item, index) => index.toString()}
  //       renderItem={({ item, index }: { item: IRune, index: number }) => <Image key={index} source={item.img} style={styles.runeImg} />}
  //     />
  //   )
  // }

  if (counter.length === 0) {
    return (
      <View style={[styles.container, { paddingTop: 100 }]}>
        <Text style={styles.nullResult}>{'РЕЗУЛЬТОВ ЕЩЕ НЕТ!'}</Text>
        <Text style={styles.nullResult}>{'ПРОЙДИТЕ ТЕСТ'}</Text>
      </View>
    )
  }

  const renderDescription = () => {
    return steps.map((el: IRune, i: number) => (
      <View key={i} style={{ alignSelf: 'center', alignItems: 'center' }}>
        <Text style={styles.descriptionRune}>{'Руна: ' + el.name}</Text>
        <Text style={styles.description}>{el.description}</Text>
      </View>
    ))
  }

  return (
    <>
      {/*<View style={isMobile ? styles.viewRunesMobile : styles.viewRunes}>{renderRunesFlatList()}</View>*/}
      <View style={styles.container}>{renderDescription()}
        <Button mode={'outlined'} style={styles.button} onPress={() => backToHome()}>
          <Text style={styles.buttonText}>{'В начало'}</Text>
        </Button>
        <View style={{ height: 50 }}/>
      </View>
    </>
  )
}

export default ResultScreen
