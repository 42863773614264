import { useNavigation } from '@react-navigation/native'
import Footer from 'components/footer'
import { useColors } from 'hooks/useColors'
import { useStyles } from 'hooks/useStyles'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Image, Text, useWindowDimensions, View } from 'react-native'
import { List } from 'react-native-paper'
import { useDispatch } from 'react-redux'
import { WIDTH_CONTEXT } from 'res/consts'
import { SCREEN_ABOUT, SCREEN_MAIN } from 'res/screens'
import { setTitle } from 'store/headerRunes'
// @ts-ignore
import { lang } from 'utils/lang'

import stylesConfig from './HomeScreen.styles'

const HomeScreen = () => {
  const styles = useStyles(stylesConfig)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const colors = useColors()
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(setTitle('ГЛАВНАЯ'))
      document.title = 'ГЛАВНАЯ'
    })

    return unsubscribe
  }, [navigation])

  const browser = () => {
    if (isMobile) {
      return { width: width - 10 }
    }
    return { marginHorizontal: width < WIDTH_CONTEXT ? 20 : 0, width: width < WIDTH_CONTEXT ? width - 40 : WIDTH_CONTEXT }
  }

  return (
    <View style={[styles.centerView, browser()]}>
      <View style={isMobile ? styles.viewHeaderMobile : styles.viewHeader}>
        <Image source={require('assets/images/logo.jpg')} style={isMobile ? styles.logoMobile : styles.logo} />
        <View style={styles.headerView}>
          <Text style={isMobile ? styles.headerMobile : styles.header}>{lang('home.title')}</Text>
        </View>
      </View>
      <List.Section>
        {/*<List.Subheader style={styles.subHeader}>{lang('home.title')}</List.Subheader>*/}
        <List.Item
          key={0}
          title={lang('home.description')}
          description={lang('home.about')}
          left={(props) => <List.Icon {...props} icon="home" color={colors.text} />}
          onPress={() => {
            // @ts-ignore
            navigation.navigate(SCREEN_ABOUT)
          }}
          rippleColor={'#ccc'}
          underlayColor={'blue'}
          style={styles.itemStyle}
          titleStyle={styles.itemTitle}
          descriptionStyle={styles.itemSubtitle}
        />
        <List.Item
          key={1}
          title={lang('home.demo')}
          description={lang('home.demo-description')}
          left={(props) => <List.Icon {...props} icon="ab-testing" color={colors.text} />}
          onPress={() => {
            // @ts-ignore
            navigation.navigate(SCREEN_MAIN)
          }}
          rippleColor={'#ccc'}
          underlayColor={'blue'}
          style={styles.itemStyle}
          titleStyle={styles.itemTitle}
          descriptionStyle={styles.itemSubtitle}
        />
        <List.Item
          key={2}
          title={lang('home.version-full')}
          // description={lang('home.version-description')}
          left={(props) => <List.Icon {...props} icon="inbox-full" color={colors.text} />}
          onPress={() => {}}
          rippleColor={'#ccc'}
          underlayColor={'blue'}
          style={styles.itemStyle}
          titleStyle={styles.itemTitle}
          descriptionStyle={styles.itemSubtitle}
        />
      </List.Section>
      <Footer />
    </View>
  )
}

export default HomeScreen
