import * as consts from './consts'
import * as description from './description'
import * as images from './images'
import * as runes from './runes'
import * as screens from './screens'
import theme from './theme'

const R = {
  runes,
  theme,
  screens,
  images,
  description,
  consts
}

export default R
