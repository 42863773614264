import ModeTheme from 'components/header/right/theme'
import { useStyles } from 'hooks/useStyles'
import React from 'react'
import { Platform, View } from 'react-native'
import { Divider, List, Menu } from 'react-native-paper'

import stylesConfig from './Menu.styles'

const MenuHomeScreen = ({ colors }: any) => {
  const styles = useStyles(stylesConfig)

  return (
    <>
      {Platform.OS === 'web' ? <ModeTheme colors={colors} /> : null}
    </>
  )
}

export default MenuHomeScreen
