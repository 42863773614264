import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Animated, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { addStep } from 'store/counterRunes'
// @ts-ignore
import { IRune } from 'types/components'

import styles from './RuneComponent.styles'

interface IProps {
  children: any
  setCount: (count: number) => void
  count: number
  item: IRune
  currentPage: number
  notPress: boolean
  setVisible: (visible: boolean) => void
}

const RuneComponent = ({ children, setCount, count, item, currentPage, notPress, setVisible }: IProps) => {
  const dispatch = useDispatch()
  // const counter = useSelector((state: RootState) => state.counter.prediction)
  const [fadeAnimation, setFadeAnimation] = useState(new Animated.Value(0))
  // const [rune, setRune] = useState<IRune>({
  //   name: '',
  //   weight: 0,
  //   img: ''
  // })

  const fadeIn = () => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false
    }).start()
  }

  const fadeOut = () => {
    setCount(count + 1)
    if (currentPage > 6) {
      dispatch(
        addStep({
          name: item.name,
          weight: 4 - count + item.weight,
          img: item.img,
          description: item.description
        })
      )
    } else {
      dispatch(
        addStep({
          name: item.name,
          weight: 4 - count,
          img: item.img,
          description: item.description
        })
      )
    }
    Animated.timing(fadeAnimation, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false
    }).start()
  }

  useEffect(() => {
    fadeIn()

    // if (counter && counter.length > 0 && currentPage > 6) {
    //   const r = counter[0].steps.filter((step) => {
    //     return step.name === item.name
    //   })
    //
    //   if (r && r[0]) {
    //     setRune(r[0])
    //   }
    // }
  }, [])

  return (
    <TouchableOpacity onPress={() => (!notPress ? fadeOut() : setVisible(true))} style={styles.container}>
      <Animated.View
        style={[
          isMobile ? styles.fadingContainerMobile : styles.fadingContainer,
          {
            opacity: fadeAnimation
          }
        ]}
      >
        {children}
      </Animated.View>
    </TouchableOpacity>
  )
}

export default RuneComponent
