import _ from 'lodash'
import { Text } from 'react-native'
import { IRune, Prediction } from 'types/components'

export const shuffle = (array: IRune[]) => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const setCustomText = (customProps: { style: any }) => {
  // @ts-ignore
  const TextRender = Text.render

  // @ts-ignore
  Text.defaultProps = Text.defaultProps || {}

  // @ts-ignore
  const initialDefaultProps = Text.defaultProps
  // @ts-ignore
  Text.defaultProps = {
    ...initialDefaultProps,
    ...customProps
  }
  // @ts-ignore
  Text.render = function render(props) {
    let oldProps = props
    props = { ...props, style: [customProps.style, props.style] }
    try {
      return TextRender.apply(this, arguments)
    } finally {
      props = oldProps
    }
  }
}

export const setGlobalFn = (fn: any) => {
  // @ts-ignore
  global.runes.fnBack = fn
}

export const calculationRunes = (counter: Prediction[], step: number) => {
  if (counter && counter.length > 0) {
    let viewResult = {
      test: [],
      view: []
    }
    let arr = []

    // @ts-ignore
    arr = counter[counter.length - 1].steps.slice(24)

    const arrNew = _.orderBy(arr, ['weight'], 'desc')

    // @ts-ignore
    viewResult.test = arrNew

    let view: IRune[] = []
    let i = 0

    const b8 = arrNew.filter((rune) => {
      return rune.weight >= 8
    })

    if (step === 11 && b8.length > 0) {
      const r = arrNew.filter((rune) => {
        return rune.weight === b8[0].weight
      })
      // @ts-ignore
      viewResult.view = r

      return viewResult
    }

    if (b8.length > 0) {
      view = view.concat(b8)
      i = i + b8.length
    }

    const b7 = arrNew.filter((rune) => {
      return rune.weight === 7
    })

    if (step === 11 && b7.length > 0) {
      // @ts-ignore
      viewResult.view = b7

      return viewResult
    }

    if (b7.length > 0 && i + b7.length <= 8) {
      view = view.concat(b7)
      i = i + b7.length
    }

    if (i >= 8) {
      // @ts-ignore
      // setSteps(view)
      viewResult.view = view

      return viewResult
    }

    const b6 = arrNew.filter((rune) => {
      return rune.weight === 6
    })

    if (b6.length > 0 && i + b6.length <= 8) {
      view = view.concat(b6)
      i = i + b6.length
    } else {
      // @ts-ignore
      viewResult.view = view

      return viewResult
    }

    const b5 = arrNew.filter((rune) => {
      return rune.weight === 5
    })

    if (b5.length > 0 && i + b5.length <= 8) {
      view = view.concat(b5)
      i = i + b5.length
    } else {
      // @ts-ignore
      viewResult.view = view

      return viewResult
    }

    const b4 = arrNew.filter((rune) => {
      return rune.weight === 4
    })

    if (b4.length > 0 && i + b4.length <= 8) {
      view = view.concat(b4)
      i = i + b4.length
    } else {
      // @ts-ignore
      viewResult.view = view

      return viewResult
    }

    const b3 = arrNew.filter((rune) => {
      return rune.weight === 3
    })

    if (b3.length > 0 && i + b3.length <= 8) {
      view = view.concat(b3)
      i = i + b3.length
    } else {
      // @ts-ignore
      viewResult.view = view

      return viewResult
    }

    const b2 = arrNew.filter((rune) => {
      return rune.weight === 2
    })

    if (b2.length > 0 && i + b2.length <= 8) {
      view = view.concat(b2)
      i = i + b2.length
    } else {
      // @ts-ignore
      viewResult.view = view

      return viewResult
    }

    // @ts-ignore
    viewResult.view = view

    return viewResult
  }
}
