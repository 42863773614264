import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IHeader {
  title: string | undefined
  subtitle: string | undefined
}

const initialState: IHeader = {
  title: '',
  subtitle: ''
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string | undefined>) => {
      state.title = action.payload
    },
    setSubTitle: (state, action: PayloadAction<string | undefined>) => {
      state.subtitle = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setTitle, setSubTitle } = headerSlice.actions

export default headerSlice.reducer
