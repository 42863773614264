import { isMobile } from 'react-device-detect'
import { Platform } from 'react-native'
import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  container: Platform.select({
    web: {
      paddingLeft: 20
    },
    ios: {}
  }),
  menu: Platform.select({
    web: {
      paddingLeft: isMobile ? '10@s' : '6@s',
      color: colors.text
    },
    ios: {
      color: colors.text,
      paddingBottom: 3
    }
  })
}))
