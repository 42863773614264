import { createStyles } from 'utils/createStyles'

export default createStyles((colors) => ({
  container: {
    width: '100vw',
    height: '100vh'
  },
  centerView: {
    width: '50vw',
    // height: '100vh',
    alignSelf: 'center',
    backgroundColor: colors.background
  },
  text: {
    color: colors.text,
    fontSize: 17,
    fontFamily: 'Exo2'
  },
  link: {
    color: colors.primary,
    fontSize: 17,
    fontFamily: 'Exo2'
  }
}))
