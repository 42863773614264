// import { useNavigation } from '@react-navigation/native'
import { MoreSvg } from 'assets/svg/icons'
import { useColors } from 'hooks/useColors'
import { useStyles } from 'hooks/useStyles'
import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Menu } from 'react-native-paper'
import { useSelector } from 'react-redux'
// @ts-ignore
import { SCREEN_HOME } from 'res/screens'
import { RootState } from 'store/store'

import { MenuHomeScreen } from './menu'
import stylesConfig from './Right.styles'

const Right = () => {
  // const navigation = useNavigation()
  const styles = useStyles(stylesConfig)
  // const dispatch = useDispatch()
  const colors = useColors()
  const currentRouteName = useSelector((state: RootState) => state.app.currentRouteName)

  const [visible, setVisible] = useState(false)

  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)

  if (currentRouteName !== SCREEN_HOME) {
    return null
  }

  return (
    <View style={styles.container}>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <TouchableOpacity onPress={() => openMenu()} style={{ paddingBottom: 3 }}>
            <MoreSvg width={26} height={26} fill={colors.logo} />
          </TouchableOpacity>
        }
      >
        <MenuHomeScreen colors={colors} />
      </Menu>
    </View>
  )
}

export default Right
