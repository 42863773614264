import { DefaultTheme } from '@react-navigation/native'

const theme = {
  light: {
    dark: false,
    colors: {
      ...DefaultTheme.colors,
      primary: 'rgb(0, 122, 255)',
      background: 'rgb(255, 255, 255)',
      backgroundBody: 'rgb(250,250,250)',
      card: 'rgb(255, 255, 255)',
      text: 'rgb(28, 28, 30)',
      border: 'rgb(216, 216, 216)',
      dialog: '#ccc',
      notification: 'rgb(255, 59, 48)',
      listitem: 'rgb(242, 242, 242)',
      logo: 'rgb(92,67,218)',
      logoText: 'rgb(225,225,250)',
      logoBorder: 'rgb(92,67,218)'
    }
  },
  dark: {
    dark: true,
    colors: {
      ...DefaultTheme.colors,
      primary: 'rgb(10, 132, 255)',
      background: 'rgb(1, 1, 1)',
      backgroundBody: 'rgb(87,87,87)',
      card: 'rgb(18, 18, 18)',
      text: 'rgb(229, 229, 231)',
      border: 'rgb(39, 39, 41)',
      dialog: '#ccc',
      notification: 'rgb(255, 69, 58)',
      listitem: 'rgb(101,101,101)',
      logo: 'rgb(180,180,211)',
      logoText: 'rgb(180,180,211)',
      logoBorder: 'rgb(255,255,255)'
    }
  }
}

export type TTheme = typeof theme.light & typeof theme.dark
export type TThemeColors = typeof theme.light.colors & typeof theme.dark.colors

export default theme
