import React from 'react'
import Svg, { Path } from 'react-native-svg'

const MoreSvg = ({ width, height, fill }: { width: number; height: number; fill: string }) => {
  return (
    <Svg width={`${width}`} height={`${height}`} fill={`${fill}`} viewBox="0 0 122 512">
      <Path
        d="M85.333,0 L36.571,0 C16.374,0 0,16.374 0,36.571 L0,85.333 C0,105.53 16.374,121.904 36.571,121.904 L85.333,121.904 C105.53,121.904 121.904,105.53 121.904,85.333 L121.904,36.571 C121.904,16.374 105.53,0 85.333,0 Z"
        id="Path"
      />
      <Path
        d="M85.333,195.048 L36.571,195.048 C16.374,195.048 0,211.422 0,231.619 L0,280.381 C0,300.578 16.374,316.952 36.571,316.952 L85.333,316.952 C105.53,316.952 121.904,300.578 121.904,280.381 L121.904,231.619 C121.904,211.422 105.53,195.048 85.333,195.048 Z"
        id="Path"
      />
      <Path
        d="M85.333,390.095 L36.571,390.095 C16.374,390.095 0,406.469 0,426.666 L0,475.428 C0,495.625 16.374,511.999 36.571,511.999 L85.333,511.999 C105.53,511.999 121.904,495.625 121.904,475.428 L121.904,426.666 C121.904,406.47 105.53,390.095 85.333,390.095 Z"
        id="Path"
      />
    </Svg>
  )
}

export default MoreSvg
